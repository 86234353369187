.projectBlock{
    position: relative;
    display: flex !important;
    align-items:center !important;
    justify-content: center !important;
    max-height: 250px;
    width: min-content;
    overflow: hidden;
    border-radius: 15px;
    /* border: 10px solid red; */
    padding: 0px !important;
    box-shadow: 3px 3px 3px rgb(200,200,200);
    margin-bottom: 20px;
}

.projectBlock:hover {
    cursor: pointer;
    opacity: 0.9;
}

.projectImage{
    max-width: 600px;
    /* width: min-content !important; */
    width: 100%;
    min-width: 200px !important;
    border-radius: 15px;
    /* border: 10px solid blue; */
}

/* row */
.projectBrand {
    position: absolute;
    display: flex;
    max-width: 100%;
    width: fit-content;
    /* background-color: white; */
    font-family: "Montserrat", sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    text-shadow: 2px 2px 3px black;
    color: white;
    /* text-align: left; */
    text-align: center;
    align-items: center !important;
    justify-content: center !important;
}

.projectBrand .projectTitle {
    display: inline-block;
    /* background-color: green; */
    /* max-width: fit-content; */
    /* min-width: fit-content; */
    width: fit-content;
}

.projectBrand p {
    display: inline;
    /* background-color: yellow; */
    margin-top: 0.7em;
    /* white-space: pre-wrap; */
    /* width: max-content; */
    /* inline-size: fit-content; */
}

.projectBrand .projectIcon {
    margin: 5px;
    flex-shrink: 0;
    height: 2em;
    /* border: solid purple; */
}

.projectBrand .projectIcon img{
    height: 100%;
    -webkit-filter: drop-shadow(2px 2px 2px black);
    filter: drop-shadow(2px 2px 2px black);
}