.slider .carousel {
    margin: 20px 0px;
    /* border: 1px solid black; */
    box-shadow: 3px 3px 3px rgb(200,200,200);
    border-radius: 15px;
    overflow: hidden;
    max-height: 500px !important;
}

.slider .carousel-item {
    max-height: 500px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    /* background-color: black; */
    overflow: hidden;
}

.slider .carousel-item * {
    width: 100%;
}

.slider .carousel-item *:hover {
    opacity: 0.95;
}