.navbar {
    background-color: #f5f5f5 !important;
    box-shadow: 0px 0px 10px 0px #c0c0c0 !important;
}

.nav-link {
    margin: 0px 20px !important;
    text-align: center;
    font-weight: 700 !important;
    border-radius: 5px !important;
}