.expandableCard .accordion-item, .accordion-button {
    border: none !important;
    border-radius: 15px;
    box-shadow: 5px 5px 5px #dfdfdf !important;
    box-shadow: none;
    background-color: transparent !important;
    overflow: hidden;
}

.expandableCard .accordion-button:not(.collapsed) {
    background-color: #f5f5f5 !important;
}

.expandableCard .accordion-button::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='black'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.expandableCard .accordion-header div {
    width: 100%;
    max-height: 250px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expandableCard .accordion-header div img {
    max-width: 100%;
    width: 600px;
}