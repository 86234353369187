.heroImg {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 500px;
    overflow: hidden;
    margin: 10px 0px;
    border-radius: 15px;
    box-shadow: 3px 3px 3px #d1d1d1;
}

.heroImg img {
    width: 100%;
}

.projectVideo {
    display: flex;
    flex-direction: column;
}

.projectVideo .iframeContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    margin: 10px 0px;
    border-radius: 15px;
    box-shadow: 3px 3px 3px #d1d1d1;
}

.projectVideo .iframeContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.projectContent a {
    font-weight: bold;
    text-decoration: none;
    color: initial;
}

.projectContent .disclaimer {
    font-style: italic;
}