.menubtn, .menubtn svg {
    fill: rgb(50, 50, 50) !important;
    border-color: transparent !important;
    background-color: transparent !important;
    border-radius: 5px !important;
}

.menubtn svg:hover {
    fill: black !important;
}

.ocnav {
    width: 100% !important;
}

.ocnav .offcanvas-header {
    background-color: #f5f5f5 !important;
    box-shadow: 4px 0px 10px 0px #c0c0c0 !important;
}

.ocnav .offcanvas-header .btn-close {
    border: none;
    box-shadow: none;
}

.ocnav .btn, .ocnav .btn:active {
    width: 100% !important;
    background-color: transparent !important;
    /* background-color: red !important; */
    color: rgb(50, 50, 50) !important;
    text-align: left !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 0px !important;
}

.ocnav .btn:hover {
    background-color: transparent !important;
    color: black !important;
    font-weight: 500 !important;
}

.ocnav .accordion {
    margin-bottom: 15px;
}

.ocnav .accordion-item {
    border: none !important;
}

.ocnav .accordion-button {
    height: 30px !important;
    padding-left: 10px !important;    
    overflow: hidden !important;
    box-shadow: none !important;
}

.ocnav .accordion-button:hover {
    background-color: transparent !important;
    color: black !important;
    font-weight: 500 !important;
}

.ocnav .accordion-button:not(.collapsed) {
    background-color: #f5f5f5 !important;
    color: black !important;
    box-shadow: 0px 2px 0px 0px #c0c0c0 !important;
}

.ocnav .accordion-button::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='black'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.ocnav .accordion-body {
    padding: 10px !important;
}