.ImageCarousel .carousel {
    margin: 20px 0px;
    /* border: 1px solid black; */
    box-shadow: 3px 3px 3px rgb(200,200,200);
    border-radius: 15px;
    overflow: hidden;
    /* max-height: 90vh !important; */
}

.ImageCarousel .carousel-item {
    /* max-height: 90vh !important; */
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    /* background-color: black; */
    overflow: hidden;
}

.ImageCarousel .carousel-item img {
    max-height: 90vh;
    max-width: 100%;
}