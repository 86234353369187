.btn {
    width: 100%;
    background-color: #f5f5f5 !important;
    border: none !important;
    height: 30px;
    margin: 0px 0px 20px 0px;
    color: black !important;
    box-shadow: 2px 2px 2px rgb(220,220,220);
}

.btn:hover {
    opacity: .85;
}