footer {
    position: relative;
    bottom: 0px;
    margin-top: 30px;
    padding-top: 30px;
    min-height: 60px;
    background-color: #f5f5f5;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
}

footer .icon {
    height: 30px;
}

footer a, footer a:visited {
    text-decoration: none;
    color: #505050;
}

footer a:hover {
    color: black;
}