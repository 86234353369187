.row {
    justify-content: center;
    align-items: center;
}

.col {
    display: flex;
    /* border: 1px solid; */
}

#profile {
    border-radius: 15px;
    max-width: 100%;
    box-shadow: 3px 3px 3px rgb(200,200,200);
}